import {useDeployAssistantUiMutation} from '#/hooks/query/assistants';
import {Input} from '#/library/Input';
import Label from '#/library/label/Label';
import {AssistantResponse} from '#/repositories/assistants-api/requests/fetch-assistant';
import {UploadIcon} from '@radix-ui/react-icons';
import {AxiosResponse} from 'axios';
import {FunctionComponent, memo, useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import {ThreeDots} from 'react-loader-spinner';
import {useToasts} from 'scout-chat/hooks/contexts/use-toasts';
import {useValidateFileSize} from 'scout-chat/hooks/logic/use-validate-file-size';

const AssistantUISection: FunctionComponent<{
  assistantId: string;
  uiUrl: string;
  setUiUrl: (uiUrl: string) => void;
}> = ({assistantId, uiUrl, setUiUrl}) => {
  const {addToast} = useToasts();
  const {t} = useTranslation();

  const onDeploySuccess = useCallback(
    (response: AxiosResponse<AssistantResponse>) => {
      addToast(t('create-edit-assistant.fields.advanced.ui.deploy-success'), 'success');
      setUiUrl(response.data.ui_url || '');
    },
    [addToast, t, setUiUrl],
  );

  const deployAssistantUiMutation = useDeployAssistantUiMutation(onDeploySuccess);

  const validateFileSize = useValidateFileSize();

  const handleDeployAssistantUi = useCallback(
    (files: File[]) => {
      files.forEach(file => {
        if (!validateFileSize(file)) {
          return;
        }
        deployAssistantUiMutation.mutate({
          assistantId,
          file,
        });
      });
    },
    [deployAssistantUiMutation, assistantId, validateFileSize],
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      handleDeployAssistantUi(acceptedFiles);
    },
    [handleDeployAssistantUi],
  );

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    noClick: true,
    multiple: false,
    accept: {
      'application/zip': ['.zip'],
    },
    disabled: deployAssistantUiMutation.isPending,
  });

  return (
    <div
      className='relative rounded-xl transition-all outline-none data-[isdragactive=true]:bg-surface-03 data-[isdragactive=true]:outline data-[isdragactive=true]:outline-4 data-[isdragactive=true]:outline-accent'
      data-isdragactive={isDragActive}
      {...getRootProps()}
    >
      <input type='file' id='deploy-assistant-ui' style={{display: 'none'}} {...getInputProps()} />

      <Label htmlFor='assistant-ui-url'>{t('create-edit-assistant.fields.advanced.ui.url-label')}</Label>
      <div className='flex items-center mt-1'>
        <Input id='assistant-ui-url' value={uiUrl} onChange={e => setUiUrl(e.target.value)} className='w-full' />

        <label
          htmlFor='deploy-assistant-ui'
          className={`h-11 w-28 ml-2 font-bold rounded flex items-center justify-center bg-accent text-accent-inverse ${deployAssistantUiMutation.isPending ? 'pointer-events-none' : 'cursor-pointer'}`}
        >
          {deployAssistantUiMutation.isPending ? (
            <ThreeDots
              visible={true}
              height='24'
              width='38'
              color='var(--color-accent-inverse)'
              radius='9'
              ariaLabel='three-dots-loading'
              wrapperClass='size-full flex justify-center items-center'
            />
          ) : (
            <UploadIcon className='size-4' />
          )}
        </label>
      </div>
    </div>
  );
};

export default memo(AssistantUISection);
