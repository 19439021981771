import {useRedirectOnInvalidConversation} from '#/hooks/chat-page/use-redirect-on-invalid-conversation';
import {useAssistantQuery} from '#/hooks/query/assistants';
import {prependCreatedConversation, useConversationsSetQueryData} from '#/hooks/query/conversations';
import {
  AssistantUserTokenResponse,
  fetchAssistantUserToken,
} from '#/repositories/assistants-api/requests/fetch-assistant-user-token';
import {castToConversationSummaryResponse} from '#/repositories/assistants-api/requests/fetch-conversations';
import {RefObject, useEffect, useMemo, useRef} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useConversationQuery} from 'scout-chat/hooks/requests/use-conversation-query';
import i18n from 'scout-chat/locales/i18n';
import ScoutAPI from 'scout-chat/repositories/scout-api';
import {ConversationResponse} from 'scout-chat/requests';

type AssistantAppPageParams = {
  assistantId: string;
  conversationId: string;
};

type ScoutApplicationIframeElement = {
  contentWindow: Window;
  src: string;
};

type PostScoutIframeTokenResponseFn = (
  scoutApplicationIframeElement: ScoutApplicationIframeElement,
  token: AssistantUserTokenResponse,
  conversationId: string | undefined,
  origin: string,
) => void;

const IFRAME_ID = 'scout-application-iframe';

const ensureTrailingSlash = (url: string): string => {
  url = url.replace(/\/[^/]+\.[^/]+$/, '/');

  if (!url.endsWith('/')) {
    return url + '/';
  }
  return url;
};

const AssistantAppPage = () => {
  const {assistantId, conversationId} = useParams<AssistantAppPageParams>();

  const lastIframeOrigin = useRef<string | null>(null);
  const lastToken = useRef<AssistantUserTokenResponse | null>(null);

  const assistantQuery = useAssistantQuery({assistantId});
  const assistantUiUrl = useMemo(() => {
    return assistantQuery?.data?.ui_url;
  }, [assistantQuery.data]);
  const assistantName = useMemo(() => {
    return assistantQuery?.data?.name;
  }, [assistantQuery.data]);

  const conversationQuery = useConversationQuery({conversationId});
  useRedirectOnInvalidConversation(conversationQuery);

  usePostScoutIframeTokenResponseOnConversationChange(conversationId, lastIframeOrigin, lastToken);

  usePrependCreatedConversationOnIframeMessage(conversationId, lastIframeOrigin, lastToken);

  useRedirectToConversationOnIframeMessage(assistantId);

  useEffect(() => {
    if (!assistantId || !assistantUiUrl) {
      return;
    }

    const handler = async (ev: MessageEvent<{type: string; message: string}>) => {
      if (typeof ev.data !== 'object') return;
      if (!ev.data.type) return;
      if (ev.data.type !== 'scout-iframe-token-request') return;
      const scoutApplicationIframeElement = getIframeElement();
      if (!ensureTrailingSlash(scoutApplicationIframeElement.src).includes(ensureTrailingSlash(assistantUiUrl))) {
        return;
      }

      lastToken.current = await fetchAssistantUserToken(assistantId, {
        assistant_ui_url: scoutApplicationIframeElement.src,
      });
      lastIframeOrigin.current = ev.origin;
      postScoutIframeTokenResponse(
        scoutApplicationIframeElement,
        lastToken.current,
        conversationId,
        lastIframeOrigin.current,
      );
    };

    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, [assistantId, assistantQuery.data, assistantUiUrl, conversationId]);
  return (
    <div className='h-full'>
      {assistantUiUrl && (
        <iframe src={assistantUiUrl} title={assistantName ?? ''} className='size-full' id={IFRAME_ID} />
      )}
    </div>
  );
};

const usePrependCreatedConversationOnIframeMessage = (
  conversationId: string | undefined,
  lastIframeOrigin: RefObject<string>,
  lastToken: RefObject<AssistantUserTokenResponse>,
) => {
  const setConversationsQueryData = useConversationsSetQueryData();

  useEffect(() => {
    const handler = async (ev: MessageEvent<{type: string; message: {createdConversation: ConversationResponse}}>) => {
      if (ev.data.type !== 'scout-iframe-conversation-created') return;

      setConversationsQueryData(oldData => {
        return prependCreatedConversation(
          oldData,
          castToConversationSummaryResponse(ev.data.message.createdConversation),
        );
      });
    };

    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, [conversationId, lastIframeOrigin, lastToken, setConversationsQueryData]);
};

const useRedirectToConversationOnIframeMessage = (assistantId: string | undefined) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!assistantId) return;

    const handler = async (ev: MessageEvent<{type: string; message: {conversationId: string}}>) => {
      if (ev.data.type !== 'scout-iframe-redirect-to-conversation') return;
      navigate(`/assistants/${assistantId}/app/${ev.data.message.conversationId}`);
    };

    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, [assistantId, navigate]);
};

const usePostScoutIframeTokenResponseOnConversationChange = (
  conversationId: string | undefined,
  lastIframeOrigin: RefObject<string>,
  lastToken: RefObject<AssistantUserTokenResponse>,
) => {
  useEffect(() => {
    if (!lastIframeOrigin.current || !lastToken.current) {
      return;
    }

    const scoutApplicationIframeElement = getIframeElement();

    postScoutIframeTokenResponse(
      scoutApplicationIframeElement,
      lastToken.current,
      conversationId,
      lastIframeOrigin.current,
    );
  }, [conversationId, lastIframeOrigin, lastToken]);
};

const postScoutIframeTokenResponse: PostScoutIframeTokenResponseFn = (
  scoutApplicationIframeElement,
  token,
  conversationId,
  origin,
) => {
  let baseUrl = ScoutAPI.defaults.baseURL;
  if (!baseUrl || baseUrl === '/api') {
    baseUrl = window.location.origin + '/api';
  }
  scoutApplicationIframeElement.contentWindow.postMessage(
    {
      type: 'scout-iframe-token-response',
      message: {
        ...token,
        language: i18n.language,
        apiBaseUrl: baseUrl,
        conversation_id: conversationId,
      },
    },
    origin,
  );
};

const getIframeElement = () => document.getElementById(IFRAME_ID) as unknown as ScoutApplicationIframeElement;

export default AssistantAppPage;
