import {ConversationSummaryResponse} from '#/repositories/assistants-api/requests/fetch-conversations';

export const assistantChatRoute = (assistant: {id: string; ui_url?: string}): string => {
  if (!!assistant?.ui_url) {
    return `/assistants/${assistant.id}/app`;
  } else {
    return `/assistants/${assistant.id}/chat`;
  }
};

export const conversationRoute = (conversation: ConversationSummaryResponse): string => {
  if (conversation.assistant_id) {
    if (conversation.has_user_data) {
      return `/assistants/${conversation.assistant_id}/app/${conversation.id}`;
    } else {
      return `/assistants/${conversation.assistant_id}/chat/${conversation.id}`;
    }
  } else {
    return `/chat/${conversation.id}`;
  }
};
