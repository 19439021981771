import {UserPublicResponse} from '#/repositories/assistants-api/requests/fetch-users';
import {LoginRequest} from '#/repositories/assistants-api/requests/login';
import {AxiosResponse} from 'axios';
import ScoutAPI from 'scout-chat/repositories/scout-api';

export type AssistantUserTokenRequest = {
  assistant_ui_url: string;
};

export type AssistantUserTokenResponse = {
  user: UserPublicResponse;
  access_token: string;
  expiry: number;
  assistant_id: string;
};

export const fetchAssistantUserToken = async (
  assistant_id: string,
  request: AssistantUserTokenRequest,
): Promise<AssistantUserTokenResponse> => {
  return (
    await ScoutAPI.post<LoginRequest, AxiosResponse<AssistantUserTokenResponse>>(
      `/assistants/${assistant_id}/user_token`,
      request,
    )
  ).data;
};
