import {GlobeIcon} from '@radix-ui/react-icons';
import {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ToolUsedItemProps, ToolUsedItemWrapper} from 'scout-chat/components/tools-used/ToolUsedItem.tsx';
import {castJson} from 'scout-chat/utils/json-utils.ts';

interface GoogleSearchToolArguments {
  query: string;
}

const GoogleSearchToolUsedItem: FunctionComponent<ToolUsedItemProps> = ({toolCall}) => {
  const {t} = useTranslation();

  const toolArguments = useMemo(() => {
    return castJson<GoogleSearchToolArguments>(toolCall.arguments);
  }, [toolCall.arguments]);

  return (
    <ToolUsedItemWrapper>
      <div className='flex items-center gap-2'>
        <GlobeIcon className='inline-block size-4 text-accent shrink-0' />
        <span>{t('tool-used.tools.google-search', {query: toolArguments?.query})}</span>
      </div>
    </ToolUsedItemWrapper>
  );
};

export default GoogleSearchToolUsedItem;
