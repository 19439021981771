import {MobileHeader} from '#/components/MobileHeader.tsx';
import Page from '#/components/Page.tsx';
import {AssistantChatButtons} from '#/components/chat-page/AssistantChatButtons.tsx';
import NewConversationPresentation from '#/components/chat-page/new-conversation/NewConversationPresentation.tsx';
import useFocusPromptInputOnLocationChange from '#/hooks/chat-page/use-focus-prompt-input-on-location-change.tsx';
import {usePrependUpdatedConversationIfNeeded} from '#/hooks/chat-page/use-prepend-updated-conversation-if-needed.tsx';
import {useRedirectOnInvalidConversation} from '#/hooks/chat-page/use-redirect-on-invalid-conversation.tsx';
import {useSendMessageFromBrowserExtension} from '#/hooks/chat-page/use-send-message-from-browser-extension.tsx';
import useSendMessageOnQuerySearchParam from '#/hooks/chat-page/use-send-message-on-query-search-param.tsx';
import {useSendMessagesOnNavigateWithNewMessages} from '#/hooks/chat-page/use-send-messages-on-navigate-with-new-messages.tsx';
import {useStartStreamingOnNewConversation} from '#/hooks/chat-page/use-start-streaming-on-new-conversation.tsx';
import {
  prependCreatedConversation,
  useConversationsSetQueryData,
  useUpdateConversationMutation,
} from '#/hooks/query/conversations.tsx';
import {useAppSettings} from '#/hooks/use-app-settings.tsx';
import {castToConversationSummaryResponse} from '#/repositories/assistants-api/requests/fetch-conversations.ts';
import {ReactComponent as MicrophoneIcon} from '#/resources/microphone-icon.svg';
import {conversationRoute} from '#/utils/route-utils';
import {PlusIcon} from '@radix-ui/react-icons';
import {FunctionComponent, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Location, NavLink, useLocation, useNavigate, useParams} from 'react-router-dom';
import {Button} from 'scout-chat/components/Button';
import ModelDropdown from 'scout-chat/components/model-dropdown/ModelDropdown.tsx';
import ScoutChat from 'scout-chat/components/scout-chat/ScoutChat.tsx';
import {useScoutChatContext} from 'scout-chat/components/scout-chat/scout-chat-providers/ScoutChatContext.tsx';
import ConversationScoutChatProvider from 'scout-chat/components/scout-chat/scout-chat-providers/conversation-scout-chat/ConversationScoutChatProvider.tsx';
import {useConfig} from 'scout-chat/hooks/contexts/use-config.tsx';
import {useCreateConversationMutation} from 'scout-chat/hooks/requests/use-create-conversation-mutation';
import {ConversationResponse} from 'scout-chat/requests/fetch-conversation.ts';
import {ConversationMessage} from 'scout-chat/types.ts';
import {getTimeZoneOffset} from 'scout-chat/utils/time-utils';

type ChatPageParams = {
  conversationId: string;
  assistantId: string;
};

export type ChatPageState = {
  shouldStartStream: boolean;
  newMessages?: ConversationMessage[];
} | null;

const ChatPage: FunctionComponent = () => {
  const {
    conversationId,
    messages,
    sendMessage,
    conversationQuery,
    handleStreamChatCompletion,
    sendMessageIsPending,
    chatModels,
    selectedModelId,
    setSelectedModelId,
    promptInputRef,
    assistant,
    assistantQueryIsLoading,
    setPrompt,
  } = useScoutChatContext();
  const {t} = useTranslation();

  const location: Location<ChatPageState> = useLocation();

  useFocusPromptInputOnLocationChange(promptInputRef, location);

  useRedirectOnInvalidConversation(conversationQuery);

  useStartStreamingOnNewConversation(messages, handleStreamChatCompletion, location.state, conversationQuery);

  useSendMessageFromBrowserExtension(sendMessage);

  useSendMessagesOnNavigateWithNewMessages(location.state, sendMessage, conversationQuery);

  useSendMessageOnQuerySearchParam(sendMessage, conversationId);

  usePrependUpdatedConversationIfNeeded(conversationQuery?.data, sendMessageIsPending);

  const onStarterPromptSelect = useCallback((prompt: string) => setPrompt(prompt), [setPrompt]);

  const {conversationMaxWidth} = useAppSettings();

  const navigate = useNavigate();

  const {
    config: {features},
  } = useConfig();

  const navigate_to_realtime = useCallback(
    (conversationId: string) => {
      navigate(`/chat/${conversationId}/realtime`, {state: {backgroundLocation: `/chat/${conversationId}`}});
    },
    [navigate],
  );

  const createConversationMutation = useCreateConversationMutation();

  const handleRealtimeClick = useCallback(() => {
    const createConversationAsync = async () => {
      const response = await createConversationMutation.mutateAsync({
        title: undefined,
        time_zone_offset: getTimeZoneOffset(),
        payload: [],
        assistant_id: assistant?.id,
        model: selectedModelId || '',
      });
      navigate_to_realtime(response.data.id);
    };
    if (!!conversationId) {
      navigate_to_realtime(conversationId);
    } else {
      createConversationAsync();
    }
  }, [conversationId, assistant, createConversationMutation, navigate_to_realtime, selectedModelId]);

  return (
    <Page title={t('conversation.page-title')}>
      <ScoutChat
        maxWidth={conversationMaxWidth}
        showNewConversationPresentation={!conversationId}
        innerHeader={
          <div className='md:p-4 flex justify-between'>
            <ModelDropdown
              chatModels={chatModels}
              selectedModelId={selectedModelId}
              setSelectedModelId={setSelectedModelId}
              className='z-20 md:flex hidden'
            />
            <div className='absolute z-20 top-4 right-4'>
              <div className='flex gap-3'>
                <AssistantChatButtons assistant={assistant} />
                {features.realtime && (
                  <Button
                    onClick={handleRealtimeClick}
                    type='button'
                    variant='primary'
                    size='sm'
                    textSize='base'
                    className='h-12'
                  >
                    <MicrophoneIcon className='size-5 stroke-accent stroke-w-icon' />
                  </Button>
                )}
              </div>
            </div>
          </div>
        }
        outerHeader={
          <MobileHeader>
            <ModelDropdown
              chatModels={chatModels}
              selectedModelId={selectedModelId}
              setSelectedModelId={setSelectedModelId}
              className='md:hidden'
            />
            <NavLink
              to='/chat'
              className='aspect-square h-12 rounded-md bg-accent flex justify-center items-center hover:opacity-70 transition-opacity'
            >
              <PlusIcon className='size-7 stroke-accent-inverse' />
            </NavLink>
          </MobileHeader>
        }
        newConversationPresentation={
          <NewConversationPresentation
            assistant={assistant}
            onStarterPromptSelect={onStarterPromptSelect}
            maxWidth={conversationMaxWidth}
            assistantQueryIsLoading={assistantQueryIsLoading}
          />
        }
      />
    </Page>
  );
};

const withConversationScoutChatProvider = (Component: FunctionComponent) => {
  return () => {
    const {i18n} = useTranslation();
    const {conversationId, assistantId} = useParams<ChatPageParams>();
    const {
      config: {features},
    } = useConfig();

    const navigate = useNavigate();
    const setConversationsQueryData = useConversationsSetQueryData();
    const onCreateConversationSuccess = useCallback(
      (createdConversation: ConversationResponse) => {
        const summaryConversation = castToConversationSummaryResponse(createdConversation);
        setConversationsQueryData(oldData => {
          return prependCreatedConversation(oldData, summaryConversation);
        });
        navigate(conversationRoute(summaryConversation), {
          state: {shouldStartStream: true},
          replace: true,
        });
      },
      [navigate, setConversationsQueryData],
    );

    const {mutateAsync: updateConversationMutateAsync} = useUpdateConversationMutation();
    const onSelectedModelIdChange = useCallback(
      async (selectedModelId: string | undefined) => {
        if (conversationId) {
          await updateConversationMutateAsync({
            conversationId: conversationId,
            editedConversation: {model: selectedModelId},
          });
        }
      },
      [conversationId, updateConversationMutateAsync],
    );

    const location = useLocation();
    const onStopStreaming = useCallback(() => {
      if (location.state?.shouldStartStream) {
        navigate(location.pathname, {replace: true});
      }
    }, [navigate, location.state?.shouldStartStream, location.pathname]);

    return (
      <ConversationScoutChatProvider
        conversationId={conversationId}
        assistantId={assistantId}
        mentionAssistantEnabled={features.assistants}
        onCreateConversationSuccess={onCreateConversationSuccess}
        onSelectedModelIdChange={onSelectedModelIdChange}
        onStopStreaming={onStopStreaming}
        language={i18n.language}
      >
        <Component />
      </ConversationScoutChatProvider>
    );
  };
};

export default withConversationScoutChatProvider(ChatPage);
