import {MobileHeader} from '#/components/MobileHeader';
import Page from '#/components/Page';
import {useUserVisibleAssistants} from '#/hooks/use-user-visible-assistants.tsx';
import {PageContentHeader} from '#/library/page-content-header/PageContentHeader.tsx';
import {ReactComponent as GearIcon} from '#/resources/gear-icon.svg';
import {ReactComponent as InfoIcon} from '#/resources/info-icon.svg';
import {ReactComponent as PlusIcon} from '#/resources/plus-icon.svg';
import {assistantChatRoute} from '#/utils/route-utils';
import * as Tooltip from '@radix-ui/react-tooltip';
import React, {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, NavLink, useNavigate} from 'react-router-dom';
import AssistantAvatar from 'scout-chat/components/AssistantAvatar.tsx';
import {useAssistants} from 'scout-chat/hooks/contexts/use-assistants.tsx';
import {useConfig} from 'scout-chat/hooks/contexts/use-config.tsx';
import {AssistantPublicResponse} from 'scout-chat/requests/fetch-public-assistant.ts';
import {AssistantSharing} from 'scout-chat/types.ts';

export const AssistantCard: FunctionComponent<{assistant: AssistantPublicResponse}> = props => {
  const {t} = useTranslation();
  const {config} = useConfig();
  const navigate = useNavigate();

  const handleEditClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/assistants/${props.assistant.id}/edit`);
  };

  const ownerName =
    config.features.assistantSharing === AssistantSharing.AdminOnly
      ? config.application.companyName
      : props.assistant.owner_name;

  return (
    <Link
      draggable={false}
      to={assistantChatRoute(props.assistant)}
      className='relative flex md:flex-col items-center justify-start md:justify-center overflow-hidden text-balance rounded-2xl bg-surface-02 p-4 md:h-48 md:text-center hover:bg-surface-03 transition-colors group'
      aria-haspopup='true'
    >
      {(props.assistant.is_owner || props.assistant.is_collaborator) && (
        <div
          onClick={handleEditClick}
          className='absolute right-3 top-3 z-50 md:opacity-0 group-hover:opacity-100 hover:rotate-[30deg] transition'
          title={t('assistants.actions.edit')}
        >
          <GearIcon className='size-9 p-2 sm:p-1 sm:size-7 stroke-primary' />
        </div>
      )}

      <AssistantAvatar
        src={props.assistant?.avatar_url}
        containerClassName='md:mb-2 sm:mb-6 aspect-square size-assistant-icon'
        className='object-cover flex-none rounded-2xl sm:text-2xl'
        alt={t('conversation.assistant.avatar.alt')}
        assistantName={props.assistant.name}
      />

      <div className='flex flex-col ml-4 md:ml-0'>
        <h4 className='mb-1 line-clamp-2 max-w-full break-words font-bold leading-snug text-primary'>
          {props.assistant.name}
        </h4>

        <p className='mt-auto text-xs text-secondary'>
          {props.assistant.is_owner ? t('assistants.created-by-you') : t('assistants.created-by', {name: ownerName})}
        </p>
      </div>
    </Link>
  );
};

const SectionIconDescription: FunctionComponent<{
  title?: string;
  description: string;
}> = ({title, description, ...rest}) => (
  <Tooltip.Provider delayDuration={200}>
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <span {...rest} className='flex items-center gap-1.5'>
          <InfoIcon />
        </span>
      </Tooltip.Trigger>

      <Tooltip.Portal>
        <Tooltip.Content
          className='max-w-screen-xs flex flex-col gap-3 z-50 data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade text-primary select-none rounded-2xl bg-surface-03 p-6 leading-none will-change-[transform,opacity]'
          sideOffset={5}
          side='bottom'
        >
          <h4>{title}</h4>
          <p className='text-sm'>{description}</p>
          <Tooltip.Arrow className='fill-surface-03' />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  </Tooltip.Provider>
);

const AssistantsPageSection: FunctionComponent<{
  title: string;
  description: string;
  assistants: AssistantPublicResponse[];
}> = ({title, description, assistants}) => {
  const {t} = useTranslation();

  return (
    <div className='@container/assistant-page-section'>
      <div className='flex items-center justify-between'>
        <div className='flex gap-1.5'>
          <h3 className='font-bold'>{title}</h3>

          <SectionIconDescription title={title} description={description} />
        </div>

        <NavLink to='/assistants/all' className='font-bold hover:opacity-70 transition-opacity'>
          {t('all-assistants.view-all')}
        </NavLink>
      </div>

      <div className='mt-4 grid grid-cols-1 auto-rows-min gap-4 @lg/assistant-page-section:grid-cols-3 @3xl/assistant-page-section:grid-cols-4'>
        {assistants.map(assistant => (
          <AssistantCard key={assistant.id} assistant={assistant} />
        ))}
      </div>
    </div>
  );
};

const AssistantsPage: FunctionComponent = () => {
  const {assistants} = useAssistants();

  const {t} = useTranslation();

  const {privateAssistants, collaborativeAssistants, sharedAssistants} = useUserVisibleAssistants(assistants);

  return (
    <Page title={t('assistants.page-header.title')}>
      <MobileHeader>
        <h2 className='grow font-bold text-center md:text-left'>{t('assistants.page-header.title')} </h2>

        <NavLink
          to='/assistants/create'
          className='aspect-square h-12 rounded-md bg-accent text-accent-inverse flex justify-center items-center hover:opacity-70 transition-opacity'
        >
          <PlusIcon className='size-7 stroke-accent-inverse' />
        </NavLink>
      </MobileHeader>

      <div className='size-full flex flex-col items-stretch pb-3 md:pb-6 overflow-y-auto'>
        <PageContentHeader title={t('assistants.page-header.title')}>
          <NavLink
            to='/assistants/create'
            className='flex items-center bg-accent text-accent-inverse rounded-xl font-bold px-4 py-2 hover:opacity-70 transition-opacity'
          >
            <PlusIcon className='size-7 stroke-accent-inverse mr-2' />

            {t('create-edit-assistant.actions.create')}
          </NavLink>
        </PageContentHeader>

        <div className='max-w-page-content grow w-full flex flex-col mx-auto gap-y-10 p-4'>
          {!!privateAssistants.length && (
            <AssistantsPageSection
              title={t('assistants.sections.own.title')}
              description={t('assistants.sections.own.description')}
              assistants={privateAssistants}
            />
          )}

          {!!collaborativeAssistants.length && (
            <AssistantsPageSection
              title={t('assistants.sections.collaborative.title')}
              description={t('assistants.sections.collaborative.description')}
              assistants={collaborativeAssistants}
            />
          )}

          {!!sharedAssistants.length && (
            <AssistantsPageSection
              title={t('assistants.sections.shared-with-me.title')}
              description={t('assistants.sections.shared-with-me.description')}
              assistants={sharedAssistants}
            />
          )}
        </div>
      </div>
    </Page>
  );
};

export default AssistantsPage;
