import {MagnifyingGlassIcon} from '@radix-ui/react-icons';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {ToolUsedItemProps, ToolUsedItemWrapper} from 'scout-chat/components/tools-used/ToolUsedItem.tsx';

const BrowserFindNextToolUsedItem: FunctionComponent<ToolUsedItemProps> = ({toolCall}) => {
  const {t} = useTranslation();

  return (
    <ToolUsedItemWrapper>
      <div className='flex items-center gap-2'>
        <MagnifyingGlassIcon className='inline-block size-4 text-accent shrink-0' />
        <span>{t('tool-used.tools.browser-find-next')}</span>
      </div>
    </ToolUsedItemWrapper>
  );
};

export default BrowserFindNextToolUsedItem;
