import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {useLocalStorage} from 'react-use';
import {useConfig} from 'scout-chat/hooks/contexts/use-config.tsx';
import {dark, light} from 'scout-chat/styles/theme.ts';
import {WebappConfig} from 'scout-chat/types.ts';

export type Theme = 'light' | 'dark';
export type ThemePreference = Theme | 'system';

const getThemeFromThemePreference = (themePreference: ThemePreference) => {
  if (themePreference === 'system') {
    return getCurrentTheme();
  }
  return themePreference;
};

const updateThemeClasslist = (theme: Theme) => {
  if (theme === 'dark') {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }
};

const updateThemeCSSVariables = (theme: Theme, config: WebappConfig) => {
  const root = document.documentElement;

  const themeVariables = theme === 'dark' ? dark : light;

  root.style.setProperty(
    '--color-accent-inverse',
    theme === 'dark' ? config.application.colors.accentDarkInverse : config.application.colors.accentInverse,
  );

  root.style.setProperty(
    '--color-accent',
    theme === 'dark' ? config.application.colors.accentDark : config.application.colors.accent,
  );

  root.style.setProperty('--color-background', themeVariables.background);
  root.style.setProperty('--color-primary', themeVariables.primary);
  root.style.setProperty('--color-secondary', themeVariables.secondary);
  root.style.setProperty('--color-inverse', themeVariables.inverse);
  root.style.setProperty('--color-surface-01', themeVariables.surface['01']);
  root.style.setProperty('--color-surface-02', themeVariables.surface['02']);
  root.style.setProperty('--color-surface-03', themeVariables.surface['03']);
  root.style.setProperty('--color-stroke-main', themeVariables.stroke.main);
  root.style.setProperty('--color-info-background', themeVariables.info.background);
  root.style.setProperty('--color-info-inverse', themeVariables.info.inverse);
  root.style.setProperty('--color-warning-background', themeVariables.warning.background);
  root.style.setProperty('--color-warning-inverse', themeVariables.warning.inverse);
  root.style.setProperty('--color-danger-background', themeVariables.danger.background);
  root.style.setProperty('--color-danger-inverse', themeVariables.danger.inverse);
};

const defaultThemePreference: ThemePreference = 'system';
const _useTheme = () => {
  const [themePreference = defaultThemePreference, setThemePreference] = useLocalStorage<ThemePreference>(
    'theme-preference',
    defaultThemePreference,
  );
  const [theme, setTheme] = useState<Theme>(getThemeFromThemePreference(themePreference));
  const {config} = useConfig();

  const updateThemePreference = useCallback(
    (themePreference: ThemePreference) => {
      setThemePreference(themePreference);
      if (themePreference !== 'system') {
        setTheme(themePreference);
      }
    },
    [setThemePreference],
  );

  useEffect(() => {
    if (themePreference !== 'system') return;

    setTheme(getCurrentTheme());

    const listener = ({matches}: {matches: boolean}) => {
      setTheme(matches ? 'dark' : 'light');
    };

    const match = window.matchMedia('(prefers-color-scheme: dark)');

    match.addEventListener('change', listener);
    return () => {
      match.removeEventListener('change', listener);
    };
  }, [themePreference]);

  useEffect(() => {
    updateThemeClasslist(theme);
    updateThemeCSSVariables(theme, config);
  }, [config, theme]);

  return {theme, themePreference, setThemePreference: updateThemePreference};
};

interface ThemeContextType {
  theme: Theme;
  themePreference: ThemePreference;
  setThemePreference: (theme: ThemePreference) => void;
}

const getCurrentTheme = () => (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');

export const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export const ThemeProvider: React.FC<Pick<React.HTMLProps<HTMLElement>, 'children'>> = ({children}) => {
  const theme = _useTheme();

  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
};
