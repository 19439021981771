import {AxiosResponse} from 'axios';
import ScoutAPI from 'scout-chat/repositories/scout-api.ts';
import {ConversationResponse} from 'scout-chat/requests/fetch-conversation.ts';

export interface ConversationSummaryResponse {
  id: string;
  title: string;
  updated_at: string;
  assistant_id: string | null;
  has_user_data: boolean;
}

export interface ConversationsResponseMetadata {
  last_timestamp: number;
  limit: number;
  has_more: boolean;
}

export interface ConversationsResponse {
  conversations: ConversationSummaryResponse[];
  metadata: ConversationsResponseMetadata;
}

export const castToConversationSummaryResponse = (data: ConversationResponse): ConversationSummaryResponse => {
  return {
    id: data.id,
    title: data.title,
    updated_at: data.updated_at,
    assistant_id: data.assistant?.id || null,
    has_user_data: data.user_data !== null,
  };
};

export const fetchConversations = (
  afterTimestamp: number | null,
  limit: number,
): Promise<AxiosResponse<ConversationsResponse>> => {
  return ScoutAPI.get<null, AxiosResponse<ConversationsResponse>>('/conversations', {
    params: {
      after_timestamp: afterTimestamp,
      limit,
    },
  });
};
