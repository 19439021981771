import {SpeakerLoudIcon, StopIcon} from '@radix-ui/react-icons';
import * as Tooltip from '@radix-ui/react-tooltip';
import React, {FunctionComponent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ThreeCircles} from 'react-loader-spinner';
import {usePlayStreamingSpeechSynthesisMutation} from 'scout-chat/hooks/requests/use-play-streaming-speech-synthesis-mutation.tsx';
import {twMerge} from 'tailwind-merge';

const SpeakResponseAloudButton: FunctionComponent<{
  className?: string;
  conversationId: string | undefined;
  messageIndex: number;
}> = ({className = '', conversationId, messageIndex}) => {
  const {t} = useTranslation();

  const [playing, setPlaying] = useState(false);

  const playerRef = useRef<Howl>();
  const onPlayerCreated = useCallback((howler: Howl) => {
    playerRef.current = howler;
  }, []);
  const onPlayerPlay = useCallback(() => {
    setPlaying(true);
  }, []);
  const onPlayerEnd = useCallback(() => {
    setPlaying(false);
  }, []);
  useEffect(() => {
    return () => {
      playerRef.current?.stop();
    };
  }, []);

  const playStreamingSpeechSynthesisMutation = usePlayStreamingSpeechSynthesisMutation(
    onPlayerCreated,
    onPlayerPlay,
    onPlayerEnd,
  );

  const onSpeakAloudClick = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      playStreamingSpeechSynthesisMutation.mutate({conversationId: conversationId || '', messageIndex: messageIndex});
    },
    [conversationId, messageIndex, playStreamingSpeechSynthesisMutation],
  );

  const buttonClassNames = useMemo(
    () =>
      twMerge(
        className,
        'flex items-center justify-center size-6 rounded-lg stroke-secondary hover:text-primary hover:stroke-primary transition-colors cursor-pointer',
      ),
    [className],
  );

  const onStopClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (playerRef.current) {
      playerRef.current.stop();
    }
  }, []);

  return (
    <Tooltip.Provider delayDuration={200}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          {playing ? (
            <button className={buttonClassNames} onClick={onStopClick}>
              <StopIcon />
            </button>
          ) : playStreamingSpeechSynthesisMutation.isPending ? (
            <button className={buttonClassNames} disabled>
              <Loader />
            </button>
          ) : (
            <button
              className={buttonClassNames}
              onClick={onSpeakAloudClick}
              disabled={playStreamingSpeechSynthesisMutation.isPending}
            >
              <SpeakerLoudIcon />
            </button>
          )}
        </Tooltip.Trigger>

        <Tooltip.Portal>
          {!playStreamingSpeechSynthesisMutation.isPending && (
            <Tooltip.Content
              className='max-w-[200px] data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade bg-surface-03 text-primary select-none rounded-[4px] px-[15px] py-[10px] text-[15px] leading-none will-change-[transform,opacity] z-50'
              sideOffset={5}
              side='bottom'
            >
              {playing ? t('actions.stop.hint') : t('actions.speak-aloud.hint')}
              <Tooltip.Arrow className='fill-surface-03' />
            </Tooltip.Content>
          )}
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

const Loader = () => {
  return (
    <ThreeCircles
      visible={true}
      height='20'
      width='20'
      color='var(--color-accent)'
      ariaLabel='tool-status-loader'
      wrapperStyle={{display: 'inline-block'}}
    />
  );
};

export default SpeakResponseAloudButton;
