import {CaretDownIcon} from '@radix-ui/react-icons';
import * as RadixSelect from '@radix-ui/react-select';
import {FunctionComponent, memo, ReactNode} from 'react';

export type Option = {
  value: string;
  label: ReactNode;
  text?: string;
};

export interface SelectProps {
  value: string;
  onChange: (value: string) => void;
  options: Option[];
  fullWidth?: boolean;
  variant?: 'default' | 'bordered';
  disabled?: boolean;
}

const Select: FunctionComponent<SelectProps> = ({
  value,
  onChange,
  options,
  fullWidth = false,
  variant = 'default',
  disabled = false,
}) => {
  return (
    <RadixSelect.Root value={value} onValueChange={onChange} disabled={disabled}>
      <RadixSelect.Trigger
        className={`focus:outline-none flex items-center justify-between appearance-none bg-surface-01 data-[variant=bordered]:border-2 border-stroke-main rounded-xl py-2 data-[variant=bordered]:py-3 px-4 data-[variant=default]:text-[13px] data-[variant=default]:font-bold ${fullWidth ? 'w-full' : 'w-auto'}`}
        data-variant={variant}
      >
        <div className='truncate'>
          <RadixSelect.Value />
        </div>
        <RadixSelect.Icon>
          <CaretDownIcon className='size-5 stroke-primary' />
        </RadixSelect.Icon>
      </RadixSelect.Trigger>
      <RadixSelect.Portal>
        <RadixSelect.Content className='text-primary bg-surface-02 rounded-xl cursor-pointer z-50 max-w-select'>
          <RadixSelect.ScrollUpButton />
          <RadixSelect.Viewport>
            {options.map(option => (
              <RadixSelect.Item
                key={option.value}
                value={option.value}
                title={option.text}
                className='focus:outline-none py-2 data-[variant=bordered]:py-3 px-4 hover:bg-surface-03 rounded-xl data-[variant=default]:text-[13px] data-[variant=default]:font-bold'
                data-variant={variant}
              >
                <RadixSelect.ItemText>{option.label}</RadixSelect.ItemText>
                <RadixSelect.ItemIndicator />
              </RadixSelect.Item>
            ))}
          </RadixSelect.Viewport>
          <RadixSelect.ScrollDownButton />
        </RadixSelect.Content>
      </RadixSelect.Portal>
    </RadixSelect.Root>
  );
};

export default memo(Select);
