import ActionBar from '#/components/layout/ActionBar.tsx';
import {useCheckAuthQuery} from '#/hooks/query/auth.tsx';
import {useCheckAppVersionQuery} from '#/hooks/query/use-check-app-version';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Navigate, Outlet} from 'react-router-dom';
import {useToasts} from 'scout-chat/hooks/contexts/use-toasts';
import environment from 'scout-chat/repositories/environment';

const ProtectedLayout = () => {
  const {t} = useTranslation();
  const checkAuthQuery = useCheckAuthQuery();
  const checkVersionQuery = useCheckAppVersionQuery();
  const {addToast} = useToasts();

  useEffect(() => {
    if (!checkVersionQuery.data || checkVersionQuery.data?.data.server_version === environment.appVersion) {
      return;
    }

    addToast(t('new-version-available'), 'warning', true);
  }, [addToast, checkVersionQuery.data, t]);

  if (checkAuthQuery.isLoading) {
    return <div className='bg-background size-full' />;
  }

  if (checkAuthQuery.isError) {
    return <Navigate to='/login' />;
  }

  return (
    <div className='layout-container size-full bg-background'>
      <div className='relative flex size-full'>
        <Outlet />

        <ActionBar />
      </div>
    </div>
  );
};

export default ProtectedLayout;
