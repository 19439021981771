import AssistantAvatarMenu from '#/components/AssistantAvatarMenu.tsx';
import {MobileHeader} from '#/components/MobileHeader.tsx';
import Page from '#/components/Page.tsx';
import SharedUsersSelect from '#/components/SharedUsersSelect.tsx';
import Slider from '#/components/Slider.tsx';
import AssistantFilesSection from '#/components/assistants/AssistantFilesSection.tsx';
import {
  useAssistantLinksQuery,
  useAssistantQuery,
  useCreateAssistantMutation,
  useDeleteAssistantMutation,
  useImproveAssistantPromptMutation,
  useUpdateAssistantMutation,
} from '#/hooks/query/assistants.tsx';
import {useAvailableFunctionsQuery} from '#/hooks/query/available-functions.tsx';
import Label from '#/library/label/Label.tsx';
import {PageContentHeader} from '#/library/page-content-header/PageContentHeader.tsx';
import Select, {Option} from '#/library/select/Select.tsx';
import {Textarea} from '#/library/textarea/Textarea.tsx';
import {LinkResponse} from '#/repositories/assistants-api/requests/fetch-assistant-links.ts';
import {ContentRetrievingStrategy} from '#/repositories/assistants-api/requests/fetch-assistant.ts';
import {AvailableFunction} from '#/repositories/assistants-api/requests/fetch-available-functions.ts';
import {ModelVisibilityType, SharedUser} from '#/repositories/assistants-api/requests/visibility.ts';
import {ReactComponent as CheckmarkIcon} from '#/resources/checkmark-icon.svg';
import {ReactComponent as ScoutLogo} from '#/resources/logo.svg';
import {ReactComponent as SparkIcon} from '#/resources/spark-icon.svg';
import {ReactComponent as TrashIcon} from '#/resources/trash-icon.svg';
import {parseSharedUsersToEmails, parseUsersToEmails} from '#/utils/model-visibility-utils.ts';
import {Cross1Icon} from '@radix-ui/react-icons';
import {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import Alert from 'scout-chat/components/Alert.tsx';
import {Button} from 'scout-chat/components/Button.tsx';
import ScoutSpinner from 'scout-chat/components/ScoutSpinner.tsx';
import {useAuth} from 'scout-chat/hooks/contexts/auth/use-auth.tsx';
import {useConfig} from 'scout-chat/hooks/contexts/use-config.tsx';
import {useToasts} from 'scout-chat/hooks/contexts/use-toasts.tsx';
import {AssistantSharing, ExternalService} from 'scout-chat/types.ts';

import {PlainTextCodeEditorDescriptor} from '#/components/MDXEditorExtensions';
import AssistantUISection from '#/components/assistants/AssistantUISection';
import {Input} from '#/library/Input';
import {assistantChatRoute} from '#/utils/route-utils';
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  ButtonWithTooltip,
  CodeToggle,
  CreateLink,
  DiffSourceToggleWrapper,
  InsertCodeBlock,
  ListsToggle,
  MDXEditor,
  MDXEditorMethods,
  Separator,
  codeBlockPlugin,
  diffSourcePlugin,
  frontmatterPlugin,
  headingsPlugin,
  linkPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  quotePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import React from 'react';
import {useTheme} from 'scout-chat/hooks/contexts/use-theme.tsx';

const SECRET_PLACEHOLDER = '••••••••';

type CreateEditAssistantPageParams = {
  assistantId: string;
};

const CreateEditAssistantPage = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {theme} = useTheme();

  const {assistantId} = useParams<CreateEditAssistantPageParams>();
  const availableFunctions = useAvailableFunctionsQuery();
  const {config} = useConfig();
  const {user} = useAuth();

  const [isOwner, setIsOwnwer] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [instructions, setInstructions] = useState('');
  const [visibilityType, setVisibilityType] = useState<ModelVisibilityType>('private');
  const [sharedUsers, setSharedUsers] = useState<readonly SharedUser[]>([]);
  const [collaborators, setCollaborators] = useState<readonly SharedUser[]>([]);
  const [links, setLinks] = useState<LinkResponse[]>([{id: null, url: '', status: 'PROCESSED'}]);
  const [promptStarters, setPromptStarters] = useState(['']);
  const [useSystemPrompt, setUseSystemPrompt] = useState(true);
  const [allowedFunctions, setAllowedFunctions] = useState<AvailableFunction[] | undefined>(undefined);
  const [allowedExternalServices, setAllowedExternalServices] = useState<string[] | undefined>(undefined);
  const [contentRetrievingStrategy, setContentRetrievingStrategy] = useState<ContentRetrievingStrategy>({});
  const [uiUrl, setUiUrl] = useState('');
  const [variables, setVariables] = useState<Array<[string, string]>>([['', '']]);
  const [secrets, setSecrets] = useState<Array<[string, string | null]>>([['', '']]);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const {addToast} = useToasts();

  const [shouldPollLinks, setShouldPollLinks] = useState(false);
  const assistantQuery = useAssistantQuery({assistantId});

  const instructionsEditorRef = React.useRef<MDXEditorMethods>(null);

  useEffect(() => {
    if (assistantQuery.data) {
      setIsOwnwer(assistantQuery.data.is_owner);
      setName(assistantQuery.data.name);
      setDescription(assistantQuery.data.description);
      setInstructions(assistantQuery.data.instructions);
      setPromptStarters([...assistantQuery.data.prompt_starters, '']);
      setVisibilityType(assistantQuery.data.visibility.type);
      setSharedUsers(assistantQuery.data.visibility.shared_users || []);
      setCollaborators(assistantQuery.data.visibility.collaborators || []);
      setAvatarUrl(assistantQuery.data.avatar_url);
      setUseSystemPrompt(assistantQuery.data.use_system_prompt);
      setAllowedFunctions(assistantQuery.data.allowed_functions);
      setAllowedExternalServices(assistantQuery.data.allowed_external_services);
      setContentRetrievingStrategy(assistantQuery.data.content_retrieving_strategy);
      setUiUrl(assistantQuery.data.ui_url || '');
      setVariables([...Object.entries(assistantQuery.data.variables), ['', '']]);
      setSecrets([...assistantQuery.data.secrets.map(key => [key, null] as [string, string | null]), ['', '']]);
    } else {
      setName('');
      setDescription('');
      setInstructions('');
      setPromptStarters(['']);
      setVisibilityType('private');
      setSharedUsers([]);
      setCollaborators([]);
      setAvatarUrl(undefined);
      setUseSystemPrompt(true);
      setAllowedFunctions(undefined);
      setAllowedExternalServices(undefined);
      setContentRetrievingStrategy({});
      setUiUrl('');
      setVariables([['', '']]);
      setSecrets([['', '']]);
    }
  }, [assistantQuery.data]);

  const assistantLinksQuery = useAssistantLinksQuery({assistantId, shouldPoll: shouldPollLinks});
  useEffect(() => {
    if (assistantLinksQuery.data) {
      setLinks([...(assistantLinksQuery.data || []), {id: null, url: '', status: 'PROCESSED'}]);
    } else {
      setLinks([{id: null, url: '', status: 'PROCESSED'}]);
    }
  }, [assistantLinksQuery.data]);

  useEffect(() => {
    if (!assistantLinksQuery.data) return;

    setShouldPollLinks(links.some(link => link.status === 'PROCESSING' || link.status === 'IN_QUEUE'));
  }, [assistantLinksQuery.data, links]);

  const addAssistantMutation = useCreateAssistantMutation(() => {
    addToast(t('create-edit-assistant.toasts.create-success'), 'success');
  });

  const navigateToEdit = useCallback(
    (assistantId: string) => {
      navigate(`/assistants/${assistantId}/edit`);
    },
    [navigate],
  );

  const handleAddAssistant = useCallback(async () => {
    const response = await addAssistantMutation.mutateAsync({
      name,
      description,
      instructions,
      prompt_starters: promptStarters.filter(Boolean),
      visibility: {
        type: visibilityType,
        user_emails: parseSharedUsersToEmails(visibilityType, sharedUsers),
        collaborators: parseUsersToEmails(collaborators),
      },
      avatar_url: avatarUrl,
      use_system_prompt: useSystemPrompt,
      allowed_functions: allowedFunctions,
      allowed_external_services: allowedExternalServices,
      content_retrieving_strategy: contentRetrievingStrategy,
    });
    navigateToEdit(response.data.id);
  }, [
    addAssistantMutation,
    allowedExternalServices,
    allowedFunctions,
    avatarUrl,
    description,
    instructions,
    name,
    collaborators,
    navigateToEdit,
    promptStarters,
    sharedUsers,
    useSystemPrompt,
    visibilityType,
    contentRetrievingStrategy,
  ]);

  const updateAssistantMutation = useUpdateAssistantMutation(() => {
    addToast(t('create-edit-assistant.toasts.edit-success'), 'success');
  });

  const improvePromptMutation = useImproveAssistantPromptMutation(response => {
    if (response.data.prompt) {
      const prompt_with_backticks = response.data.prompt;
      const response_without_backticks = prompt_with_backticks.startsWith('```')
        ? prompt_with_backticks.substring(3, prompt_with_backticks.length - 3)
        : prompt_with_backticks;
      setInstructions(response_without_backticks);
    }
  });
  const {mutateAsync: mutateAsyncImprovePrompt} = improvePromptMutation;

  const instructionsRef = useRef(instructions);
  useEffect(() => {
    instructionsRef.current = instructions;
  }, [instructions]);

  const improvePrompt = useCallback(async () => {
    await mutateAsyncImprovePrompt({prompt: instructionsRef.current});
  }, [mutateAsyncImprovePrompt]);

  const updateAssistant = useCallback(async () => {
    const variableDict = variables.reduce((acc, [key, value]) => {
      if (key !== '') {
        return {...acc, [key]: value};
      }
      return acc;
    }, {});

    const secretDict = secrets.reduce((acc, [key, value]) => {
      if (key !== '') {
        return {...acc, [key]: value};
      }
      return acc;
    }, {});

    const response = await updateAssistantMutation.mutateAsync({
      assistantId: assistantId || '',
      editedAssistant: {
        name,
        description,
        instructions,
        links: links.filter(link => link.url !== ''),
        prompt_starters: promptStarters.filter(Boolean),
        visibility: {
          type: visibilityType,
          user_emails: parseSharedUsersToEmails(visibilityType, sharedUsers),
          collaborators: parseUsersToEmails(collaborators),
        },
        avatar_url: avatarUrl,
        use_system_prompt: useSystemPrompt,
        allowed_functions: allowedFunctions,
        allowed_external_services: allowedExternalServices,
        content_retrieving_strategy: contentRetrievingStrategy,
        ui_url: uiUrl || undefined,
        variables: variableDict,
        secrets: secretDict,
      },
    });
    setLinks(links.map(link => (link.id === null && link.url !== '' ? {...link, status: 'IN_QUEUE'} : link)));

    return response;
  }, [
    variables,
    secrets,
    updateAssistantMutation,
    assistantId,
    name,
    description,
    instructions,
    links,
    promptStarters,
    visibilityType,
    sharedUsers,
    collaborators,
    avatarUrl,
    useSystemPrompt,
    allowedFunctions,
    allowedExternalServices,
    contentRetrievingStrategy,
    uiUrl,
  ]);

  const navigateToChat = useCallback(
    (assistantId: string, ui_url?: string) => {
      navigate(assistantChatRoute({id: assistantId, ui_url}));
    },
    [navigate],
  );

  const handleTryAssistant = useCallback(async () => {
    const response = await updateAssistant();
    navigateToChat(response.data.id, response.data.ui_url);
  }, [navigateToChat, updateAssistant]);

  const handleUpdateAssistant = useCallback(async () => {
    const response = await updateAssistant();
    navigateToEdit(response.data.id);
  }, [navigateToEdit, updateAssistant]);

  const deleteAssistantMutation = useDeleteAssistantMutation();
  const handleDeleteAssistantConfirmation = useCallback(() => {
    deleteAssistantMutation.mutate(assistantId || '');
  }, [assistantId, deleteAssistantMutation]);

  const error = assistantQuery.error || deleteAssistantMutation.error;

  const handleDeleteAssistantRequest = useCallback(() => {
    if (!showDeleteConfirmation) {
      setShowDeleteConfirmation(true);
      return;
    }
  }, [showDeleteConfirmation]);

  const handleVisibilityChange = useCallback((newVisibility: string) => {
    setVisibilityType(newVisibility as ModelVisibilityType);
  }, []);

  const updateLink = (index: number, value: string) => {
    let updated = [...links];
    updated[index].url = value;

    updated = updated.filter((item, idx) => item.url !== '' || idx === updated.length - 1 || updated.length === 1);

    if (updated[updated.length - 1].url !== '') {
      updated.push({id: null, url: '', status: 'PROCESSED'});
    }

    setLinks(updated);
  };

  const handleRemoveLink = (index: number) => {
    setLinks(links.filter((_, i) => i !== index));
  };

  const updatePromptStarter = (index: number, value: string) => {
    let updated = [...promptStarters];
    updated[index] = value;

    updated = updated.filter((item, idx) => item !== '' || idx === updated.length - 1 || updated.length === 1);

    if (updated[updated.length - 1] !== '' && updated.length < 4) {
      updated.push('');
    }

    setPromptStarters(updated);
  };

  const handleRemovePromptStarter = (index: number) => {
    const updated = promptStarters.filter((_, i) => i !== index);

    if (updated.length === 0 || (updated[updated.length - 1] !== '' && updated.length < 4)) {
      updated.push('');
    }

    setPromptStarters(updated);
  };

  const showVisibilitySelect = useMemo(() => {
    if (
      config.features.assistantSharing === AssistantSharing.Enabled ||
      config.features.assistantSharing === AssistantSharing.Restricted
    ) {
      return true;
    } else if (config.features.assistantSharing === AssistantSharing.AdminOnly && user?.isAdmin) {
      return true;
    }
    return false;
  }, [config.features.assistantSharing, user?.isAdmin]);

  const canMakeAssistantPublic = useMemo(() => {
    if (config.features.assistantSharing === AssistantSharing.Enabled) {
      return true;
    } else if (
      (config.features.assistantSharing === AssistantSharing.Restricted ||
        config.features.assistantSharing === AssistantSharing.AdminOnly) &&
      user?.isAdmin
    ) {
      return true;
    }
    return false;
  }, [config.features.assistantSharing, user?.isAdmin]);

  const visibilityOptions: Option[] = useMemo(() => {
    return [
      {value: 'private', label: t('model-visibility.private')},
      ...(showVisibilitySelect ? [{value: 'shared', label: t('model-visibility.shared')}] : []),
      ...(canMakeAssistantPublic || visibilityType === 'public'
        ? [{value: 'public', label: t('model-visibility.public')}]
        : []),
    ];
  }, [t, canMakeAssistantPublic, showVisibilitySelect, visibilityType]);

  const toggleAllowedFunction = (functionName: AvailableFunction) => {
    if (!allowedFunctions) {
      setAllowedFunctions([functionName]);
    } else {
      if (allowedFunctions.includes(functionName)) {
        setAllowedFunctions(allowedFunctions.filter(f => f !== functionName));
      } else {
        setAllowedFunctions(allowedFunctions.concat([functionName]));
      }
    }
  };

  const toggleAllowedExternalService = (serviceName: string) => {
    if (!allowedExternalServices) {
      setAllowedExternalServices([serviceName]);
    } else {
      if (allowedExternalServices.includes(serviceName)) {
        setAllowedExternalServices(allowedExternalServices.filter(f => f !== serviceName));
      } else {
        setAllowedExternalServices(allowedExternalServices.concat([serviceName]));
      }
    }
  };

  useEffect(() => {
    if (instructionsEditorRef.current?.getMarkdown() !== instructions)
      instructionsEditorRef.current?.setMarkdown(instructions);
  }, [instructions]);

  const MDXPlugins = useMemo(
    () => [
      frontmatterPlugin(),
      headingsPlugin(),
      listsPlugin(),
      linkPlugin(),
      quotePlugin(),
      thematicBreakPlugin(),
      codeBlockPlugin({
        defaultCodeBlockLanguage: '',
        codeBlockEditorDescriptors: [PlainTextCodeEditorDescriptor],
      }),
      markdownShortcutPlugin(),
      diffSourcePlugin({
        diffMarkdown: assistantQuery.data?.instructions || '',
        viewMode: 'rich-text',
        readOnlyDiff: true,
      }),
      toolbarPlugin({
        toolbarClassName: 'bg--03 rounded-t-lg rounded-none',
        toolbarContents: () => (
          <>
            <DiffSourceToggleWrapper>
              <BlockTypeSelect />
              <BoldItalicUnderlineToggles />
              <Separator />
              <ListsToggle />
              <CreateLink />
              <CodeToggle />
              <InsertCodeBlock />
              <Separator />
              <ButtonWithTooltip
                title={t('create-edit-assistant.fields.instructions.improve')}
                onClick={improvePrompt}
                disabled={improvePromptMutation.isPending}
              >
                <ScoutLogo className={'size-5 fill-accent ' + (improvePromptMutation.isPending ? 'ai-loading ' : '')} />
              </ButtonWithTooltip>
            </DiffSourceToggleWrapper>
          </>
        ),
      }),
      markdownShortcutPlugin(),
      headingsPlugin(),
    ],
    [assistantQuery.data?.instructions, improvePrompt, improvePromptMutation.isPending, t],
  );

  const updateVariableKey = useCallback((index: number, key: string, newKey: string) => {
    setVariables(prevVariables => {
      const updatedVariables = [...prevVariables];
      updatedVariables[index] = [newKey, prevVariables[index][1]];
      return updatedVariables;
    });
  }, []);

  const updateVariableValue = useCallback((index: number, key: string, newValue: string) => {
    setVariables(prev => {
      const updatedVariables = [...prev];
      updatedVariables[index] = [prev[index][0], newValue];
      if (index === updatedVariables.length - 1) {
        updatedVariables.push(['', '']);
      }
      return updatedVariables;
    });
  }, []);

  const handleRemoveVariable = useCallback((index: number) => {
    setVariables(prev => {
      const newEntries = [...prev];
      newEntries.splice(index, 1);
      return newEntries;
    });
  }, []);

  const updateSecretKey = useCallback((index: number, key: string, newKey: string) => {
    setSecrets(prev => {
      const updatedSecrets = [...prev];
      updatedSecrets[index] = [newKey, prev[index][1]];
      return updatedSecrets;
    });
  }, []);

  const updateSecretValue = useCallback((index: number, key: string, newValue: string) => {
    setSecrets(prev => {
      const updatedSecrets = [...prev];

      updatedSecrets[index] = [prev[index][0], newValue];
      if (index === updatedSecrets.length - 1) {
        updatedSecrets.push(['', '']);
      }
      return updatedSecrets;
    });
  }, []);

  const handleRemoveSecret = useCallback((index: number) => {
    setSecrets(prev => {
      const newEntries = [...prev];
      newEntries.splice(index, 1);
      return newEntries;
    });
  }, []);

  return (
    <Page
      title={assistantId ? t('create-edit-assistant.page-title.edit') : t('create-edit-assistant.page-title.create')}
    >
      <MobileHeader>
        <h2 className='grow font-bold text-center md:text-left'>
          {assistantId ? t('create-edit-assistant.page-title.edit') : t('create-edit-assistant.page-title.create')}
        </h2>
      </MobileHeader>

      <div className='size-full flex flex-col items-stretch overflow-y-auto bg-surface-01 rounded-2xl'>
        <PageContentHeader
          title={
            assistantId ? t('create-edit-assistant.page-title.edit') : t('create-edit-assistant.page-title.create')
          }
        />

        <div className='max-w-page-content grow w-full flex flex-col mx-auto gap-y-8 p-4 md:py-0'>
          {config.features.remoteFiles && (
            <AssistantAvatarMenu
              assistantId={assistantId}
              assistantName={name}
              description={description}
              avatarUrl={avatarUrl}
              setAvatarUrl={setAvatarUrl}
            />
          )}

          <div>
            <Label htmlFor='assistant-name'>{t('create-edit-assistant.fields.name.label')}</Label>
            <Textarea
              id='assistant-name'
              value={name}
              placeholder={t('create-edit-assistant.fields.name.placeholder')}
              onValueChange={e => setName(e.target.value)}
            />
          </div>

          <div>
            <Label htmlFor='assistant-description'>{t('create-edit-assistant.fields.description.label')}</Label>
            <Textarea
              id='assistant-description'
              value={description}
              placeholder={t('create-edit-assistant.fields.description.placeholder')}
              onValueChange={e => setDescription(e.target.value)}
            />
          </div>

          <div>
            <Label htmlFor='assistant-instructions'>{t('create-edit-assistant.fields.instructions.label')}</Label>
            <MDXEditor
              ref={instructionsEditorRef}
              placeholder={t('create-edit-assistant.fields.instructions.placeholder')}
              markdown=''
              translation={t}
              /* eslint-disable-next-line tailwindcss/no-custom-classname */
              className={`${theme === 'dark' ? 'dark-theme dark-mdx-editor' : ''} border-2 border-stroke-main rounded-xl`}
              contentEditableClassName='prose dark:prose-invert w-full leading-0 py-3 px-4 overflow-y-scroll min-h-[300px] max-h-[500px] max-w-none'
              onChange={setInstructions}
              plugins={MDXPlugins}
            />

            {error && <Alert variant='warning'>{error.message}</Alert>}
          </div>

          {config.features.remoteFiles && config.features.assistantFiles && (
            <AssistantFilesSection assistantId={assistantId} />
          )}

          <div>
            <Label htmlFor='links'>{t('create-edit-assistant.fields.urls.label')}</Label>

            {!assistantId ? (
              <Alert variant='info'>{t('create-edit-assistant.fields.knowledge.disclaimer')}</Alert>
            ) : (
              <>
                {links.map((link, index) => (
                  <div key={index} className='flex items-center mt-1'>
                    <Textarea
                      id={`assistant-links-${index}`}
                      value={link.url}
                      className={link.status === 'FAILED' ? 'border-danger' : ''}
                      onValueChange={e => updateLink(index, e.target.value)}
                    />

                    {links.length > 1 && (
                      <button
                        type='button'
                        onClick={() => handleRemoveLink(index)}
                        className={`flex justify-center items-center size-11 ml-2 bg-red-500 text-white font-bold py-2 px-4 rounded ${index === links.length - 1 && !link.url ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={index === links.length - 1 && !link.url}
                      >
                        {link.status === 'PROCESSING' || link.status === 'IN_QUEUE' ? (
                          <div className='size-full flex items-center justify-center'>
                            <ScoutSpinner />
                          </div>
                        ) : (
                          <Cross1Icon className='shrink-0' />
                        )}
                      </button>
                    )}
                  </div>
                ))}
              </>
            )}
          </div>

          {showVisibilitySelect && (
            <div>
              <Label htmlFor='assistant-visibility'>{t('model-visibility.label')}</Label>
              <Select
                value={visibilityType}
                onChange={handleVisibilityChange}
                options={visibilityOptions}
                fullWidth={true}
                variant='bordered'
                disabled={visibilityType === 'public' && !canMakeAssistantPublic}
              />

              {visibilityType === 'shared' && (
                <SharedUsersSelect className='mt-2' sharedUsers={sharedUsers} setSharedUsers={setSharedUsers} />
              )}
            </div>
          )}

          {showVisibilitySelect && (
            <div>
              <Label htmlFor='assistant-collaborators'>{t('model-collaborators.label')}</Label>
              <SharedUsersSelect className='mt-2' sharedUsers={collaborators} setSharedUsers={setCollaborators} />
            </div>
          )}

          <div>
            <Label htmlFor='prompt-starters'>{t('create-edit-assistant.fields.conversation-starters.label')}</Label>
            {promptStarters.map((starter, index) => (
              <div key={index} className='flex items-center mt-1'>
                <Textarea
                  id={`assistant-prompt-starters-${index}`}
                  value={starter}
                  placeholder={t('create-edit-assistant.fields.conversation-starters.placeholder')}
                  onValueChange={e => updatePromptStarter(index, e.target.value)}
                />

                {promptStarters.length > 1 && (
                  <button
                    type='button'
                    onClick={() => handleRemovePromptStarter(index)}
                    className={`h-11 ml-2 bg-red-500 text-white font-bold py-2 px-4 rounded ${index === promptStarters.length - 1 && !starter ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={index === promptStarters.length - 1 && !starter}
                  >
                    <Cross1Icon />
                  </button>
                )}
              </div>
            ))}
          </div>

          <div>
            <button onClick={() => setShowAdvanced(prev => !prev)} className='hover:opacity-70 transition-opacity'>
              <Label htmlFor='advanced'>{t('create-edit-assistant.fields.advanced.label')}</Label>
            </button>
            <div hidden={!showAdvanced} className='transition-opacity ml-2'>
              <div className='flex flex-col'>
                <label className='font-bold mb-2'>{t('create-edit-assistant.fields.advanced.embeddings_title')}</label>
                <div className='flex  items-center'>
                  <label className='mr-2 w-[30%]'>
                    {t('create-edit-assistant.fields.advanced.embeddings_max_result_count')}
                  </label>
                  <Slider
                    className='w-64'
                    initialValue={[30]}
                    min={5}
                    max={200}
                    step={5}
                    value={
                      contentRetrievingStrategy.max_result_count
                        ? [contentRetrievingStrategy.max_result_count]
                        : undefined
                    }
                    onValueChange={value =>
                      setContentRetrievingStrategy(prev => ({...prev, max_result_count: value[0]}))
                    }
                    label={t('create-edit-assistant.fields.advanced.embeddings_max_result_count')}
                  />
                </div>
                <div className='flex items-center'>
                  <label className='mr-2 w-[30%]'>
                    {t('create-edit-assistant.fields.advanced.embeddings_min_score')}
                  </label>
                  <Slider
                    className='w-64'
                    initialValue={[30]}
                    min={10}
                    max={95}
                    step={5}
                    value={
                      contentRetrievingStrategy.min_score
                        ? [Math.round(contentRetrievingStrategy.min_score * 100)]
                        : undefined
                    }
                    onValueChange={value =>
                      setContentRetrievingStrategy(prev => ({...prev, min_score: value[0] / 100}))
                    }
                    label={t('create-edit-assistant.fields.advanced.embeddings_min_score')}
                  />
                </div>
                <div className='flex items-center'>
                  <label className='mr-2 w-[30%]'>
                    {t('create-edit-assistant.fields.advanced.embeddings_keyword_search_count')}
                  </label>
                  <Slider
                    className='w-64'
                    initialValue={[1]}
                    min={1}
                    max={5}
                    step={1}
                    value={
                      contentRetrievingStrategy.keyword_search_count
                        ? [contentRetrievingStrategy.keyword_search_count + 1]
                        : undefined
                    }
                    onValueChange={value =>
                      setContentRetrievingStrategy(prev => ({...prev, keyword_search_count: value[0] - 1}))
                    }
                    label={t('create-edit-assistant.fields.advanced.embeddings_keyword_search_count')}
                  />
                </div>
                <label className='items-center'>
                  <input
                    type='checkbox'
                    checked={contentRetrievingStrategy?.filter_results ?? false}
                    onChange={value => {
                      setContentRetrievingStrategy(prev => ({...prev, filter_results: value.target.checked}));
                    }}
                    className='mr-2'
                  />
                  {t('create-edit-assistant.fields.advanced.embeddings_filter')}
                </label>
              </div>

              <div className='mt-8 mb-2'>
                <label className='font-bold mb-4'>
                  {t('create-edit-assistant.fields.advanced.instructions_title')}
                </label>
              </div>
              <div>
                <label className='items-center'>
                  <input
                    type='checkbox'
                    checked={useSystemPrompt}
                    onChange={() => {
                      setUseSystemPrompt(!useSystemPrompt);
                    }}
                    className='mr-2'
                  />
                  {t('create-edit-assistant.fields.advanced.use_system_prompt')}
                </label>
              </div>
              <div>
                <label className='items-center'>
                  <input
                    type='checkbox'
                    checked={!allowedFunctions}
                    onChange={() => {
                      setAllowedFunctions(allowedFunctions ? undefined : []);
                    }}
                    className='mr-2'
                  />
                  {t('create-edit-assistant.fields.advanced.all_available_functions')}
                </label>
              </div>
              <div className='ml-2'>
                {availableFunctions &&
                  availableFunctions.map((functionName: AvailableFunction, index) => (
                    <Fragment key={index}>
                      <div className='flex items-center'>
                        <label>
                          <input
                            type='checkbox'
                            checked={!!allowedFunctions?.includes(functionName)}
                            onChange={() => {
                              toggleAllowedFunction(functionName);
                            }}
                            className='mr-2'
                          />
                          {t(`create-edit-assistant.fields.advanced.functions.${functionName}`)}
                        </label>
                      </div>
                      {functionName === 'execute_saas_requests' && allowedFunctions?.includes(functionName) && (
                        <>
                          <div className='ml-2'>
                            <label>
                              <input
                                type='checkbox'
                                checked={!allowedExternalServices}
                                onChange={() => {
                                  setAllowedExternalServices(allowedExternalServices ? undefined : []);
                                }}
                                className='mr-2'
                              />
                              {t('create-edit-assistant.fields.advanced.all_available_external_services')}
                            </label>
                          </div>
                          {config.features.externalServices.map((service: ExternalService, index: number) => (
                            <div key={service.name} className='ml-4'>
                              <label>
                                <input
                                  type='checkbox'
                                  checked={!!allowedExternalServices?.includes(service.name)}
                                  onChange={() => {
                                    toggleAllowedExternalService(service.name);
                                  }}
                                  className='mr-2'
                                />
                                {service.display_name}
                              </label>
                            </div>
                          ))}
                        </>
                      )}
                    </Fragment>
                  ))}
              </div>
              <div className='mt-8 mb-2'>
                {assistantId && <AssistantUISection assistantId={assistantId} uiUrl={uiUrl} setUiUrl={setUiUrl} />}
              </div>

              <div>
                <div className='mt-8 mb-2'>
                  <Label htmlFor='assistant-variables'>
                    {t('create-edit-assistant.fields.advanced.variables-secrets.variables-label')}
                  </Label>
                </div>
                {variables.map(([key, value], index) => (
                  <div key={index} className='flex items-center mt-1'>
                    <div className='w-full flex flex-row gap-2'>
                      <Input
                        placeholder={t('create-edit-assistant.fields.advanced.variables-secrets.key.placeholder')}
                        className='w-1/2'
                        value={key}
                        onChange={e => updateVariableKey(index, key, e.target.value)}
                      />
                      <Input
                        placeholder={t('create-edit-assistant.fields.advanced.variables-secrets.value.placeholder')}
                        className='w-1/2'
                        value={value}
                        onChange={e => updateVariableValue(index, key, e.target.value)}
                      />
                    </div>

                    <button
                      type='button'
                      onClick={() => handleRemoveVariable(index)}
                      className={`h-11 ml-2 bg-red-500 text-white font-bold py-2 px-4 rounded ${index === variables.length - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                      disabled={index === variables.length - 1}
                    >
                      <Cross1Icon />
                    </button>
                  </div>
                ))}
              </div>
              <div>
                <div className='mt-8 mb-2'>
                  <Label htmlFor='assistant-variables'>
                    {t('create-edit-assistant.fields.advanced.variables-secrets.secrets-label')}
                  </Label>
                </div>
                {secrets.map(([key, value], index) => (
                  <div key={index} className='flex items-center mt-1'>
                    <div className='w-full flex flex-row gap-2'>
                      <Input
                        placeholder={t('create-edit-assistant.fields.advanced.variables-secrets.key.placeholder')}
                        className='w-1/2'
                        value={key}
                        onChange={e => updateSecretKey(index, key, e.target.value)}
                      />
                      <Input
                        placeholder={
                          value === null
                            ? SECRET_PLACEHOLDER
                            : t('create-edit-assistant.fields.advanced.variables-secrets.value.placeholder')
                        }
                        className='w-1/2'
                        value={value || ''}
                        onChange={e => updateSecretValue(index, key, e.target.value)}
                      />
                    </div>

                    <button
                      type='button'
                      onClick={() => handleRemoveSecret(index)}
                      className={`h-11 ml-2 bg-red-500 text-white font-bold py-2 px-4 rounded ${index === secrets.length - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                      disabled={index === secrets.length - 1}
                    >
                      <Cross1Icon />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className='z-10 sticky flex flex-col gap-0 bottom-0 bg-transparent'>
          <div className='w-full h-8 bg-gradient-to-t from-surface-01' />

          <div className='max-w-page-content w-full mx-auto bg-surface-01 flex justify-end items-center md:pb-6 pb-3 pt-2 px-4'>
            <div className='w-full flex items-center justify-end gap-4 flex-wrap max-w-fit'>
              {!assistantId ? (
                <>
                  <Button
                    type='button'
                    variant='primary'
                    size='sm'
                    textSize='base'
                    isLoading={addAssistantMutation.isPending}
                    disabled={addAssistantMutation.isPending || !name || !instructions}
                    onClick={handleAddAssistant}
                  >
                    {t('create-edit-assistant.actions.create')}
                  </Button>
                </>
              ) : !showDeleteConfirmation ? (
                <>
                  {isOwner && (
                    <Button
                      type='button'
                      variant='cancel'
                      size='sm'
                      textSize='base'
                      onClick={handleDeleteAssistantRequest}
                    >
                      <TrashIcon className='stroke-accent-inverse' />
                      {t('create-edit-assistant.actions.delete')}
                    </Button>
                  )}
                  <Button
                    type='button'
                    variant='primary'
                    size='sm'
                    textSize='base'
                    isLoading={updateAssistantMutation.isPending}
                    disabled={updateAssistantMutation.isPending}
                    onClick={handleUpdateAssistant}
                  >
                    <CheckmarkIcon className='stroke-accent-inverse' />
                    {t('create-edit-assistant.actions.save')}
                  </Button>

                  <Button
                    type='button'
                    variant='secondary'
                    size='sm'
                    textSize='base'
                    isLoading={updateAssistantMutation.isPending}
                    disabled={updateAssistantMutation.isPending}
                    onClick={handleTryAssistant}
                  >
                    <SparkIcon className='stroke-primary' />
                    {t('create-edit-assistant.actions.try')}
                  </Button>
                </>
              ) : (
                <>
                  <div className='flex items-center justify-end text-right'>
                    <span className='text-red-600 text-sm'>
                      {t('create-edit-assistant.delete-confirmation.message')}
                      <br /> <strong>{t('create-edit-assistant.delete-confirmation.warning')}</strong>
                    </span>
                  </div>
                  <Button
                    type='button'
                    variant='cancel'
                    size='sm'
                    textSize='base'
                    isLoading={deleteAssistantMutation.isPending}
                    disabled={deleteAssistantMutation.isPending}
                    onClick={handleDeleteAssistantConfirmation}
                  >
                    {t('create-edit-assistant.delete-confirmation.confirmation-button')}
                  </Button>
                  <Button
                    type='button'
                    variant='secondary'
                    size='sm'
                    textSize='base'
                    onClick={() => {
                      setShowDeleteConfirmation(false);
                    }}
                  >
                    {t('conversation.actions.cancel.content')}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default CreateEditAssistantPage;
