import {MagnifyingGlassIcon} from '@radix-ui/react-icons';
import {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ToolUsedItemProps, ToolUsedItemWrapper} from 'scout-chat/components/tools-used/ToolUsedItem.tsx';
import {castJson} from 'scout-chat/utils/json-utils.ts';

interface BrowserFindToolArguments {
  search_string: string;
}

const BrowserFindToolUsedItem: FunctionComponent<ToolUsedItemProps> = ({toolCall}) => {
  const {t} = useTranslation();

  const toolArguments = useMemo(() => {
    return castJson<BrowserFindToolArguments>(toolCall.arguments);
  }, [toolCall.arguments]);

  return (
    <ToolUsedItemWrapper>
      <div className='flex items-center gap-2'>
        <MagnifyingGlassIcon className='inline-block size-4 text-accent shrink-0' />
        <span>{t('tool-used.tools.browser-find', {search_string: toolArguments?.search_string})}</span>
      </div>
    </ToolUsedItemWrapper>
  );
};

export default BrowserFindToolUsedItem;
