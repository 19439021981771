import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';

const ACCESS_TOKEN_STORAGE_KEY = 'access_token';
const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token';

const isLocalhost = window.location.hostname === 'localhost';

const getCookieOptions = (overrides = {}) => {
  const sameSiteValue: 'Lax' | 'Strict' | 'None' = isLocalhost ? 'Lax' : 'Strict';

  return {
    expires: 31,
    secure: !isLocalhost,
    sameSite: sameSiteValue,
    domain: window.location.hostname,
    ...overrides,
  };
};

export interface TokenManager {
  getAccessToken(): Promise<string>;
  getRefreshToken(): Promise<string>;
  setAccessToken(token: string): void;
  setRefreshToken(token: string): void;
  removeAllTokens(): void;
}

class CookieTokenManager implements TokenManager {
  getAccessToken() {
    return Promise.resolve(Cookies.get(ACCESS_TOKEN_STORAGE_KEY) || '');
  }

  getRefreshToken() {
    return Promise.resolve(Cookies.get(REFRESH_TOKEN_STORAGE_KEY) || '');
  }

  setAccessToken(token: string) {
    Cookies.set(ACCESS_TOKEN_STORAGE_KEY, token, getCookieOptions());
  }

  setRefreshToken(token: string) {
    Cookies.set(REFRESH_TOKEN_STORAGE_KEY, token, getCookieOptions());
  }

  removeAllTokens() {
    Cookies.remove(ACCESS_TOKEN_STORAGE_KEY, getCookieOptions());
    Cookies.remove(REFRESH_TOKEN_STORAGE_KEY, getCookieOptions());
  }
}

let tokenManager: TokenManager = new CookieTokenManager();

export const setTokenManager = (customTokenManager: TokenManager) => {
  tokenManager = customTokenManager;
};

export const getAccessToken = () => tokenManager.getAccessToken();
export const getRefreshToken = () => tokenManager.getRefreshToken();
export const setAccessToken = (token: string) => tokenManager.setAccessToken(token);
export const setRefreshToken = (token: string) => tokenManager.setRefreshToken(token);
export const removeAllTokens = () => tokenManager.removeAllTokens();

interface TokenPayload {
  sub: string;
  first_name: string;
  last_name: string;
  picture_url?: string;
  is_admin?: boolean;
}

export const getTokenPayload = async (): Promise<TokenPayload | null> => {
  const accessToken = await getAccessToken();

  if (!accessToken) {
    return null;
  }

  const decodedPayload = jwtDecode<TokenPayload>(accessToken);

  return decodedPayload;
};
