import {useTranslation} from 'react-i18next';
import {ThreeCircles} from 'react-loader-spinner';
import {ToolStatusLoaderProps} from 'scout-chat/components/tool-loaders/ToolStatusLoader.tsx';

const DefaultToolLoader = (props: ToolStatusLoaderProps) => {
  const {t} = useTranslation();

  return (
    <div className='flex gap-1 items-center'>
      <DefaultLoader />
      <span>{t('tools.default')}</span>
    </div>
  );
};

export default DefaultToolLoader;

export const DefaultLoader = () => {
  return (
    <ThreeCircles
      visible={true}
      height='20'
      width='20'
      color='var(--color-accent)'
      ariaLabel='tool-status-loader'
      wrapperStyle={{display: 'inline-block'}}
    />
  );
};
