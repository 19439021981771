import {MobileHeader} from '#/components/MobileHeader.tsx';
import Page from '#/components/Page.tsx';
import APITokens from '#/components/settings/APITokensSection';
import ExternalServicesList from '#/components/settings/ExternalServicesList.tsx';
import PreferencesList from '#/components/settings/PreferencesList.tsx';
import {PageContentHeader} from '#/library/page-content-header/PageContentHeader.tsx';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import environment from 'scout-chat/repositories/environment';

const SettingsPage: FunctionComponent = () => {
  const {t} = useTranslation();

  return (
    <Page title={t('settings.page-header.title')}>
      <MobileHeader>
        <h2 className='grow font-bold text-center md:text-left'>{t('settings.page-header.title')} </h2>
      </MobileHeader>

      <div className='size-full flex flex-col items-stretch overflow-y-auto'>
        <PageContentHeader title={t('settings.page-header.title')} subtitle={t('settings.page-header.description')} />

        <div className='max-w-page-content grow w-full flex flex-col mx-auto gap-y-8 p-4 md:pt-0'>
          <PreferencesList />

          <APITokens />

          <ExternalServicesList />

          {environment.appVersion && (
            <div className='flex justify-end text-sm opacity-50'>
              {t('settings.app-version', {version: environment.appVersion})}
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};

export default SettingsPage;
