import {ConversationSummaryResponse} from '#/repositories/assistants-api/requests/fetch-conversations';
import {FunctionComponent, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useConversations} from '#/hooks/use-conversations.tsx';
import {useConversationsGroupedByDate} from '#/hooks/useConversationsGroupedByDate';
import {ConversationsList} from '#/library/conversations-list/ConversationsList';
import React from 'react';
import {Flipper} from 'react-flip-toolkit';

type ConversationsListGroupsProps = React.HTMLAttributes<HTMLDivElement> & {
  conversations: ConversationSummaryResponse[];
};
export const ConversationsListGroups: FunctionComponent<ConversationsListGroupsProps> = ({
  conversations,
  className,
}) => {
  const {t} = useTranslation();
  const conversationsGroupedByDate = useConversationsGroupedByDate(conversations);
  const [editingConversationId, setEditingConversationId] = useState<string | null>(null);
  const [openMenuUuid, setOpenMenuUuid] = useState<string | null>(null);
  const {activeConversationId} = useConversations();

  return (
    <Flipper
      flipKey={conversations.map(conversation => conversation.id).join('')}
      className={`flex flex-col gap-5 px-4 md:px-0 ${className || ''}`}
    >
      {conversationsGroupedByDate.map(([key, conversations], index) => (
        <ConversationsList
          key={index}
          title={t(`sidebar.conversations.dateGroups.${key}`)}
          conversations={conversations}
          editingConversationId={
            conversations.some(conv => conv.id === editingConversationId) ? editingConversationId : null
          }
          setEditingConversationId={setEditingConversationId}
          openMenuUuid={conversations.some(conv => conv.id === openMenuUuid) ? openMenuUuid : null}
          setOpenMenuUuid={setOpenMenuUuid}
          activeConversationId={activeConversationId}
        />
      ))}
    </Flipper>
  );
};
