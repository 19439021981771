import {useConversationsQuery} from '#/hooks/query/conversations.tsx';
import {ConversationSummaryResponse} from '#/repositories/assistants-api/requests/fetch-conversations';
import React, {ReactNode, createContext, useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

interface ConversationsContextType {
  activeConversationId: string | null;
  conversations: ConversationSummaryResponse[];
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  hasMore: boolean;
}

const ConversationsContext = createContext<ConversationsContextType | undefined>(undefined);

export const useConversations = () => {
  const context = useContext(ConversationsContext);
  if (context === undefined) {
    throw new Error('useConversations must be used within a ConversationsProvider');
  }
  return context;
};

const CONVERSATION_PAGE_SIZE = 25;

export const ConversationsProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const [activeConversationId, setActiveConversationId] = useState<string | null>(null);
  const location = useLocation();

  const {
    data: conversations,
    fetchNextPage,
    hasMore,
    isFetchingNextPage,
  } = useConversationsQuery(CONVERSATION_PAGE_SIZE);

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    let chatIndex = pathParts.indexOf('chat');
    if (chatIndex === -1) {
      chatIndex = pathParts.indexOf('app');
    }
    const uuid = chatIndex !== -1 && pathParts.length > chatIndex + 1 ? pathParts[chatIndex + 1] : null;
    setActiveConversationId(uuid);
  }, [location.pathname]);

  return (
    <ConversationsContext.Provider
      value={{
        activeConversationId,
        conversations,
        fetchNextPage,
        isFetchingNextPage,
        hasMore,
      }}
    >
      {children}
    </ConversationsContext.Provider>
  );
};
