import {assistantChatRoute} from '#/utils/route-utils';
import {GearIcon, InfoCircledIcon, PlusIcon} from '@radix-ui/react-icons';
import {FunctionComponent, memo} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink, useLocation} from 'react-router-dom';
import {AssistantPublicResponse} from 'scout-chat/requests/fetch-public-assistant.ts';
import {twMerge} from 'tailwind-merge';

export const AssistantChatButtons: FunctionComponent<{assistant?: AssistantPublicResponse; className?: string}> = memo(
  ({assistant, className}) => {
    const {t} = useTranslation();
    const location = useLocation();

    return (
      <div className={twMerge('flex gap-3', className)}>
        {assistant && !assistant?.is_owner && !assistant?.is_collaborator && (
          <NavLink
            to={`/assistants/${assistant.id}/info`}
            className='flex gap-2 h-12 rounded-lg bg-surface-02 p-4 items-center z-10 hover:opacity-70 transition-opacity group'
            state={{backgroundLocation: location}}
          >
            <InfoCircledIcon className='size-5 stroke-primary stroke-w-icon' />
          </NavLink>
        )}

        {assistant && (assistant?.is_owner || assistant?.is_collaborator) && (
          <NavLink
            to={`/assistants/${assistant.id}/edit`}
            className='flex gap-2 h-12 rounded-lg bg-surface-02 p-4 items-center z-10 hover:opacity-70 transition-opacity group'
          >
            <GearIcon className='group-hover:rotate-[30deg] transition size-5 stroke-primary stroke-w-icon' />
            <span className='font-bold'>{t('conversation.assistant.edit-button')}</span>
          </NavLink>
        )}

        {assistant && (
          <NavLink
            to={assistantChatRoute(assistant)}
            className='flex gap-2 h-12 rounded-lg bg-surface-02 p-4 items-center z-10 hover:opacity-70 transition-opacity group'
          >
            <PlusIcon className='size-5 stroke-primary stroke-w-icon' />
          </NavLink>
        )}
      </div>
    );
  },
);
