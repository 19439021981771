import {AxiosResponse} from 'axios';
import ScoutAPI from 'scout-chat/repositories/scout-api.ts';
import {AssistantPublicResponse} from 'scout-chat/requests/fetch-public-assistant.ts';
import {ConversationMessage} from 'scout-chat/types.ts';

export interface ConversationResponse {
  id: string;
  title: string;
  payload: ConversationMessage[];
  updated_at: string;
  assistant?: AssistantPublicResponse;
  model: string | null;
  user_data: Record<string, unknown> | null;
}

export const fetchConversation = (uuid: string): Promise<AxiosResponse<ConversationResponse>> => {
  return ScoutAPI.get<null, AxiosResponse<ConversationResponse>>(`/conversations/${uuid}`);
};
