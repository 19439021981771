import {useLogin} from '#/hooks/use-login.tsx';
import {useAuth0} from '@auth0/auth0-react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ThreeDots} from 'react-loader-spinner';
import {useNavigate} from 'react-router-dom';
import LegacyButton from 'scout-chat/components/LegacyButton.tsx';
import {useErrorMessage} from 'scout-chat/hooks/logic/use-error-message.tsx';

const Auth0LoginButton = (props: {setErrorMessage: (errorMessage: string) => void}) => {
  const {t} = useTranslation();
  const {errorMessageForError} = useErrorMessage();

  const [isPending, setIsPending] = useState(false);
  const [didCloseLoginPopup, setDidCloseLoginPopup] = useState(false);

  const {login} = useLogin();
  const auth0 = useAuth0();
  const navigate = useNavigate();

  const handleClick = async () => {
    setDidCloseLoginPopup(false);
    setIsPending(true);
    await auth0.loginWithPopup();
    setIsPending(false);
    setDidCloseLoginPopup(true);
  };

  useEffect(() => {
    if (!didCloseLoginPopup || !auth0.isAuthenticated) return;
    const handleLogin = async () => {
      setIsPending(true);
      try {
        const idToken = await auth0.getIdTokenClaims();
        if (!idToken) {
          throw new Error('Invalid token');
        }

        await login({
          oauth_token: idToken.__raw,
          nonce: null,
          provider: 'auth0',
        });

        await auth0.logout({
          openUrl: false,
        });

        navigate('/chat');
      } catch (error) {
        props.setErrorMessage(errorMessageForError(error, null));
      } finally {
        setIsPending(false);
      }
    };
    handleLogin();
  }, [didCloseLoginPopup, auth0.isAuthenticated, auth0, login, navigate, props, t, errorMessageForError]);

  return (
    <LegacyButton
      className='bg-accent hover:opacity-70 transition-opacity text-accent-inverse font-bold py-2 px-4 rounded-md w-full'
      onClick={handleClick}
    >
      {isPending ? (
        <ThreeDots
          visible={true}
          height='24'
          width='38'
          color='var(--color-accent-inverse)'
          radius='9'
          ariaLabel='three-dots-loading'
          wrapperStyle={{}}
          wrapperClass='justify-center'
        />
      ) : (
        t('login.button')
      )}
    </LegacyButton>
  );
};

export default Auth0LoginButton;
