import {deleteConversation} from '#/repositories/assistants-api/requests/delete-conversation.ts';
import {deleteConversations} from '#/repositories/assistants-api/requests/delete-conversations';
import {
  ConversationSummaryResponse,
  ConversationsResponse,
  fetchConversations,
} from '#/repositories/assistants-api/requests/fetch-conversations.ts';
import {
  UpdateConversationRequest,
  updateConversation,
} from '#/repositories/assistants-api/requests/update-conversation.ts';
import {InfiniteData, useInfiniteQuery, useMutation, useQueryClient} from '@tanstack/react-query';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useAuth} from 'scout-chat/hooks/contexts/auth/use-auth.tsx';
import {useToasts} from 'scout-chat/hooks/contexts/use-toasts.tsx';
import {useErrorMessage} from 'scout-chat/hooks/logic/use-error-message.tsx';

export const useConversationsQuery = (limit: number) => {
  const {user} = useAuth();

  const {data, fetchNextPage, isFetchingNextPage} = useInfiniteQuery<
    ConversationsResponse,
    Error,
    InfiniteData<ConversationsResponse, number | null>,
    [string | undefined, string],
    number | null
  >({
    queryKey: [user?.id, 'conversations'],
    queryFn: async ({pageParam: afterTimestampParam = null}) => {
      const response = await fetchConversations(afterTimestampParam, limit);
      return response.data;
    },
    getNextPageParam: lastPage => {
      return lastPage.metadata.has_more ? lastPage.metadata.last_timestamp : undefined;
    },
    initialPageParam: null,
  });

  return {
    data: data?.pages.flatMap(page => page.conversations) || [],
    fetchNextPage,
    isFetchingNextPage,
    hasMore: data?.pages.at(-1)?.metadata.has_more ?? false,
  };
};

export interface PaginatedConversations {
  pageParams: number[];
  pages: ConversationsResponse[];
}

export interface PaginatedConversationsUpdater {
  (oldData: PaginatedConversations): PaginatedConversations;
}

export const useConversationsSetQueryData = () => {
  const {user} = useAuth();
  const queryClient = useQueryClient();

  return useCallback(
    (modifyData: PaginatedConversationsUpdater) => {
      queryClient.setQueryData([user?.id, 'conversations'], modifyData);
    },
    [queryClient, user?.id],
  );
};

export const useUpdateConversationMutation = () => {
  const {addToast} = useToasts();
  const setConversationsQueryData = useConversationsSetQueryData();
  const {errorMessageForError} = useErrorMessage();
  const queryClient = useQueryClient();
  const {user} = useAuth();

  return useMutation({
    mutationKey: ['update-conversation'],
    mutationFn: async (data: {conversationId: string; editedConversation: UpdateConversationRequest}) => {
      return updateConversation(data.conversationId, data.editedConversation);
    },
    onSuccess: ({data: updatedConversation}) => {
      setConversationsQueryData(oldData => {
        return prependUpdatedConversation(oldData, updatedConversation);
      });
      queryClient.invalidateQueries({queryKey: [user?.id, 'conversation', updatedConversation.id]});
    },
    onError: error => {
      addToast(errorMessageForError(error, 'sidebar.conversations.toasts.update-failure'), 'error');
    },
  });
};

export const useDeleteConversationMutation = () => {
  const {t} = useTranslation();
  const {addToast} = useToasts();
  const setConversationsQueryData = useConversationsSetQueryData();

  return useMutation({
    mutationKey: ['delete-conversation'],
    mutationFn: deleteConversation,
    onSuccess: ({data: deletedConversationId}) => {
      setConversationsQueryData(oldData => {
        const newData = shallowCopyPaginatedConversations(oldData);
        newData.pages = newData.pages.map(page => ({
          ...page,
          conversations: page.conversations.filter(conversation => conversation.id !== deletedConversationId),
        }));
        return newData;
      });
    },
    onError: () => {
      addToast(t('errors.delete-conversation'), 'error');
    },
  });
};

export const useDeleteConversationsMutation = () => {
  const {t} = useTranslation();
  const {addToast} = useToasts();
  const setConversationsQueryData = useConversationsSetQueryData();

  return useMutation({
    mutationKey: ['delete-conversations'],
    mutationFn: deleteConversations,
    onSuccess: ({data: deletedConversationIds}) => {
      setConversationsQueryData(oldData => {
        const newData = shallowCopyPaginatedConversations(oldData);
        newData.pages = newData.pages.map(page => ({
          ...page,
          conversations: page.conversations.filter(conversation => !deletedConversationIds.includes(conversation.id)),
        }));
        return newData;
      });
    },
    onError: () => {
      addToast(t('errors.delete-conversations'), 'error');
    },
  });
};

export const shallowCopyPaginatedConversations = (oldData: PaginatedConversations) => {
  return {
    ...oldData,
    pages: oldData.pages.map(page => ({
      ...page,
      conversations: [...page.conversations],
    })),
  };
};

export const prependCreatedConversation = (
  oldData: PaginatedConversations,
  createdConversation: ConversationSummaryResponse,
) => {
  const newData = shallowCopyPaginatedConversations(oldData);
  newData.pages[0].conversations.unshift(createdConversation);
  return newData;
};

export const prependUpdatedConversation = (
  oldData: PaginatedConversations,
  updatedConversation: ConversationSummaryResponse,
) => {
  const newData = shallowCopyPaginatedConversations(oldData);

  let conversationFound = false;
  for (const page of newData.pages) {
    const index = page.conversations.findIndex(conversation => conversation.id === updatedConversation.id);

    if (index !== -1) {
      page.conversations.splice(index, 1);
      conversationFound = true;
      break;
    }
  }

  if (conversationFound) {
    newData.pages[0].conversations.unshift(updatedConversation);
  }

  return newData;
};
