import {GlobeIcon} from '@radix-ui/react-icons';
import {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import ToolUsedItem, {ToolUsedItemProps, ToolUsedItemWrapper} from 'scout-chat/components/tools-used/ToolUsedItem.tsx';
import {ReactComponent as AgentIcon} from 'scout-chat/resources/agent.svg';
import {MessageMetadata} from 'scout-chat/types';
import {castJson} from 'scout-chat/utils/json-utils';

interface WebSearchAgentToolArguments {
  request: string;
}

const WebSearchAgentToolUsedItem: FunctionComponent<ToolUsedItemProps> = ({toolCall}) => {
  const {t} = useTranslation();
  const toolArguments = useMemo(() => {
    return castJson<WebSearchAgentToolArguments>(toolCall.arguments);
  }, [toolCall.arguments]);

  const internal_tool_calls = useMemo(() => {
    const output_metadata = toolCall.output_metadata;
    if (!!output_metadata) {
      return (output_metadata as unknown as MessageMetadata).tool_calls;
    } else {
      return null;
    }
  }, [toolCall]);

  return (
    <div>
      <ToolUsedItemWrapper>
        <div className='flex items-center gap-2' title={toolArguments?.request}>
          <div className='flex items-center gap-1'>
            <AgentIcon className='inline-block size-4 text-accent stroke-accent stroke-2 shrink-0' />
            <GlobeIcon className='inline-block size-4 text-accent shrink-0' />
          </div>
          {toolArguments && <span>{t('tool-used.tools.web-search-agent')}</span>}
        </div>
      </ToolUsedItemWrapper>
      <div className='pl-6'>
        {internal_tool_calls &&
          internal_tool_calls.map((internal_tool_call, index) => (
            <div className='overflow-hidden p-2 flex flex-col gap-2 rounded-lg'>
              <ToolUsedItem key={index} toolCall={internal_tool_call} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default WebSearchAgentToolUsedItem;
